import './App.css';
import me from './me2.png';
import awsBadge from './aws.png';
import extLinkIcon from './external-link-icon.png';
import resume from './zane_partain_2024_resume_website.pdf';
import { TypeAnimation } from 'react-type-animation';
import React, { useState, useEffect} from 'react';
import { Parallax, useParallax } from 'react-scroll-parallax';
import { Link, Element } from 'react-scroll';



function App(){
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const checkIfAtBottom = () => {
    const scrolled = window.scrollY;
    const bottom = document.documentElement.scrollHeight - window.innerHeight;
    setIsAtBottom(scrolled === bottom);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth"});
  };

  useEffect(() => {
    window.addEventListener('scroll', checkIfAtBottom);
    return () => window.removeEventListener('scroll', checkIfAtBottom);
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validate = () => {
    let formErrors = {};

    if (!formData.name.trim()) formErrors.name = 'Name is required';
    if (!formData.email) {
      formErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Email is invalid';
    }
    if (!formData.message.trim()) formErrors.message = 'Message is required';

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setSubmitted(true);
      setFormData({ name: '', email: '', message: '' });
    }
  };

  return (
    <div className="App">
        <div className="navbar">
          <div className="container">
            <div className="navbar-items">
              
              <div className="navbar-logo">
                Zane Partain
              </div> 
              
              <Link to="contact-form" smooth={true} duration={1000}>
                <div className="navbar-contact">
                  Contact me
                </div>
              </Link>

            </div>
          </div>
        </div>

        {/*  ================================================================================= */}

        <div className="hero">
          <div className="container">
            <div className="hero-items">
              <div className="hero-text-container">
                  <div className="hero-text">I'm Zane, a</div>
                    <div style={{width: "100%"}}>
                      <span className="hero-text"> 
                        Software
                      </span>
                        <TypeAnimation
                          sequence={[
                            ' Engineer',
                            2000,
                            ' Maestro',
                            2000,
                            ' Developer',
                            2000,
                            ' Alchemist',
                            2000
                          ]}
                          wrapper="span"
                          speed={30}
                          deletionSpeed={30}
                          className="hero-text type"
                          preRenderFirstString
                          repeat={Infinity}
                        />
                    </div>

                <a className="aws" href="https://www.credly.com/badges/50e22e57-d9df-441d-9cca-69e96397fa1c/public_url" target="AWS Certified Developer Certification" rel="noopener noreferrer">
                    <img className="aws-img" src={awsBadge}></img>
                    AWS Certified Developer <img style={{width: "17px", marginLeft: "5px", paddingTop: "6px"}} src={extLinkIcon}></img>
                </a>
                
                <Link to="contact-form" smooth={true} duration={1000}>
                  <button className="hero-contact-button">
                    Contact me
                  </button>
                </Link>
              </div>
            
              <div className="hero-img-container">
                <img className="hero-img" src={me} />
              </div>

            </div>
          </div>
        </div>

        {/*  ================================================================================= */}
        <div style={{position: "relative", height: "fit-content", width: "100%", paddingTop: "50px", paddingBottom: "100px"}}>


          <div className='quick-links-top'>
          </div>

          <div className="quick-links">
            <div className="container">
          
              <div className="quick-links-items">
                <a className="aws" href="https://www.linkedin.com/in/zane-partain/" target="LinkedIn profile" rel="noopener noreferrer">
                  <div id="linkedin-link" className="card">
                    <div className="card-img">

                    </div>
                    <div className="card-title">
                        LINKEDIN
                    </div>
                  </div>
                </a>

                <a className="aws" href="https://www.github.com/zane-partain/" target="GitHub account" rel="noopener noreferrer">
                  <div id="github-link" className="card">
                    <div className="card-img">

                    </div>
                    <div className="card-title">
                        GITHUB
                    </div>
                  </div>
                </a>
                
                <a className="aws" href={resume} target="_blank" rel="noopener noreferrer">
                  <div id="resume-link" className="card">
                    <div className="card-img">

                    </div>
                    <div className="card-title">
                        RESUME
                    </div>
                  </div>
                </a>

              </div>
            </div>

          </div>
          <div className='quick-links-bottom'>
          </div>
        </div>
        
        {/*  ================================================================================= */}

        <div className="contact-form">
          <div className="container">
            <Element className="contact-form-items" name="contact-form">
              <div id="contact" className="card">
                <h2 style={{textAlign: "left", marginBottom: "8px"}}>Contact me</h2>
                {submitted 
                ? <p style={{textAlign: "left"}}>Thank you for reaching out! I'll get back to you soon.</p> 
                : <form onSubmit={handleSubmit} noValidate>
                    <div style={{display: "flex"}}>
                      <div style={{width: "70%", marginRight: "8px"}}>
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {errors.name && <p className="error">{errors.name}</p>}
                      </div>
                      
                      <div style={{width: "100%"}}>
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {errors.email && <p className="error">{errors.email}</p>}
                      </div>
                    </div>

                    <div>
                      <label htmlFor="message">Message</label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      {errors.message && <p className="error">{errors.message}</p>}
                    </div>
                    
                    <button className="contact-form-button" type="submit">Submit</button>
                  </form>
                }

              </div>

              <div className="contact-img" style={{width: "100%"}}>
              </div>
            </Element>
            
          </div>
        </div>
            {isAtBottom && (
              <button className="to-top-btn" onClick={scrollToTop}>
                Go Back to Top
              </button>
            )}
    </div>
  );
}

export default App;
